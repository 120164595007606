<template>
    <div class="userManagement-container">
        <vab-query-form>
            <vab-query-form-left-panel :span="12">
                <el-form :inline="true" :model="queryForm" @submit.native.prevent>
                    <el-form-item>
                        <el-input v-model.trim="queryForm.name"
                                  placeholder="请输入岗位名称"
                                  clearable />
                    </el-form-item>

                    <el-form-item>
                        <el-select v-model.trim="queryForm.isDisable"  placeholder="请选择" >
                            <el-option v-for="item in isDisable"
                                       :key="item.value"
                                       :label="item.name"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item>
                        <el-button icon="el-icon-search" type="primary" @click="queryData">
                            查询
                        </el-button>

                        <router-link :to="{name:'Detail',query:{id: 0}}" style="margin-left:10px">
                            <el-button icon="el-icon-plus" type="primary">
                                添加
                            </el-button>
                        </router-link>

                    </el-form-item>
                </el-form>
            </vab-query-form-left-panel>
        </vab-query-form>

        <el-table v-loading="listLoading"
                  :data="list"
                  border
                  :element-loading-text="elementLoadingText"
                  @selection-change="setSelectRows">

            <el-table-column show-overflow-tooltip label="操作" width="100" align="center">
                <template #default="{ row }">
                    <router-link :to="{name:'Detail',query:{id: row.id}}">编辑</router-link>

                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip
                             width="60px"
                             prop="id"
                             label="ID"></el-table-column>

            <el-table-column show-overflow-tooltip
                             width="150px"
                             prop="name"
                             label="岗位名称"></el-table-column>

            <el-table-column show-overflow-tooltip
                             width="150px"
                             prop="code"
                             label="岗位编号"></el-table-column>



            <el-table-column show-overflow-tooltip
                             width="100px"
                             prop="cityName"
                             label="岗位城市">
            </el-table-column>

            <el-table-column show-overflow-tooltip
                             width="100px"
                             prop="wages"
                             label="岗位工资">
            </el-table-column>


            <el-table-column show-overflow-tooltip
                             width="100px"
                             prop="wagesUnit"
                             label="结算方式">
                <template #default="{ row }">
                    <el-tag type="success">{{ salaryUnit.find(d=>d.value==row.wagesUnit).name }}</el-tag>
                </template>
            </el-table-column>


            <el-table-column show-overflow-tooltip
                             width="100px"
                             prop="commision"
                             label="佣金"></el-table-column>


            <el-table-column show-overflow-tooltip
                             width="150px"
                             prop="salary"
                             label="岗位月薪"></el-table-column>

            <el-table-column show-overflow-tooltip
                             width="100px"
                             prop="sort"
                             label="岗位排序"></el-table-column>

            <el-table-column show-overflow-tooltip
                             width="120px"
                             label="性别限制">
                <template #default="{ row }">
                    <el-tag type="success">{{row.sexLimit ==0 ? '不限': (row.sexLimit ==1 ? '男' : '女') }}</el-tag>
                </template>
            </el-table-column>

            <!--<el-table-column show-overflow-tooltip
                     width="120px"
                     label="岗位分类">
        <template #default="{ row }">
            <el-tag type="success">{{row.categoryName}}</el-tag>
        </template>
    </el-table-column>-->


            <el-table-column show-overflow-tooltip
                             width="120px"
                             label="岗位类别">
                <template #default="{ row }">
                    <el-tag type="success">{{row.typeName}}</el-tag>
                </template>
            </el-table-column>


            <el-table-column show-overflow-tooltip
                             width="200px"
                             label="学历限制">
                <template #default="{ row }">
                    {{ educationList.find(v=>v.id==row.educationId).name }}
                </template>
            </el-table-column>


            <el-table-column show-overflow-tooltip
                             width="100px"
                             label="状态">
                <template #default="{ row }">
                    <el-tag v-if="row.isDisable" type="info">禁用</el-tag>
                    <el-tag v-else type="success">启用</el-tag>
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip
                             width="160px"
                             prop="creationTime"
                             label="创建时间">
                <template slot-scope="scope">
                    {{scope.row.creationTime| DateFormat}}
                </template>
            </el-table-column>
        </el-table>

        <el-pagination background
                       :current-page="queryForm.pageIndex"
                       :page-size="queryForm.pageSize"
                       :layout="layout"
                       :total="total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"></el-pagination>



        <edit ref="edit" @fetch-data="fetchData"></edit>
    </div>
</template>

<script>
    import { salaryUnit, educationList,isDisable } from '@/config/data';
    import { getPositionsByPage } from '@/api/position';
    import Edit from './components/AddPosition';

    export default {
        name: 'PositionList',
        components: { Edit },
        data() {
            return {
                educationList: educationList,
                isDisable: isDisable,
                salaryUnit: salaryUnit,
                list: null,
                listLoading: false,
                layout: 'total, sizes, prev, pager, next, jumper',
                total: 0,
                selectRows: '',
                elementLoadingText: '正在加载...',
                queryForm: {
                    pageIndex: 1,
                    pageSize: 10,
                    name: '',
                    isDisable: null,
                },
            }
        },
        created() {
            this.fetchData()
        },
        methods: {
            setSelectRows(val) {
                this.selectRows = val
            },
            handleEdit(row) {
                if (row.id) {
                    this.$refs['edit'].showEdit(row);
                } else {
                    this.$refs['edit'].showEdit();
                }
            },

            handleSizeChange(val) {
                this.queryForm.pageSize = val;
                this.fetchData();
            },
            handleCurrentChange(val) {
                this.queryForm.pageIndex = val;
                this.fetchData();
            },
            queryData() {
                this.queryForm.pageIndex = 1;
                this.fetchData();
            },
            async fetchData() {
                const { isSucceed, message, result, total } = await getPositionsByPage(this.queryForm);
                if (!isSucceed) return this.$baseMessage(message, 'error');
                //console.log(result)
                this.list = result;
                this.total = total;
            },
        },
    }
</script>

