var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"userManagement-container"},[_c('vab-query-form',[_c('vab-query-form-left-panel',{attrs:{"span":12}},[_c('el-form',{attrs:{"inline":true,"model":_vm.queryForm},nativeOn:{"submit":function($event){$event.preventDefault();}}},[_c('el-form-item',[_c('el-input',{attrs:{"placeholder":"请输入岗位名称","clearable":""},model:{value:(_vm.queryForm.name),callback:function ($$v) {_vm.$set(_vm.queryForm, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"queryForm.name"}})],1),_c('el-form-item',[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.queryForm.isDisable),callback:function ($$v) {_vm.$set(_vm.queryForm, "isDisable", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"queryForm.isDisable"}},_vm._l((_vm.isDisable),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.name,"value":item.value}})}),1)],1),_c('el-form-item',[_c('el-button',{attrs:{"icon":"el-icon-search","type":"primary"},on:{"click":_vm.queryData}},[_vm._v(" 查询 ")]),_c('router-link',{staticStyle:{"margin-left":"10px"},attrs:{"to":{name:'Detail',query:{id: 0}}}},[_c('el-button',{attrs:{"icon":"el-icon-plus","type":"primary"}},[_vm._v(" 添加 ")])],1)],1)],1)],1)],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.listLoading),expression:"listLoading"}],attrs:{"data":_vm.list,"border":"","element-loading-text":_vm.elementLoadingText},on:{"selection-change":_vm.setSelectRows}},[_c('el-table-column',{attrs:{"show-overflow-tooltip":"","label":"操作","width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('router-link',{attrs:{"to":{name:'Detail',query:{id: row.id}}}},[_vm._v("编辑")])]}}])}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","width":"60px","prop":"id","label":"ID"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","width":"150px","prop":"name","label":"岗位名称"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","width":"150px","prop":"code","label":"岗位编号"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","width":"100px","prop":"cityName","label":"岗位城市"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","width":"100px","prop":"wages","label":"岗位工资"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","width":"100px","prop":"wagesUnit","label":"结算方式"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tag',{attrs:{"type":"success"}},[_vm._v(_vm._s(_vm.salaryUnit.find(function (d){ return d.value==row.wagesUnit; }).name))])]}}])}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","width":"100px","prop":"commision","label":"佣金"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","width":"150px","prop":"salary","label":"岗位月薪"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","width":"100px","prop":"sort","label":"岗位排序"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","width":"120px","label":"性别限制"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tag',{attrs:{"type":"success"}},[_vm._v(_vm._s(row.sexLimit ==0 ? '不限': (row.sexLimit ==1 ? '男' : '女')))])]}}])}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","width":"120px","label":"岗位类别"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tag',{attrs:{"type":"success"}},[_vm._v(_vm._s(row.typeName))])]}}])}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","width":"200px","label":"学历限制"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.educationList.find(function (v){ return v.id==row.educationId; }).name)+" ")]}}])}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","width":"100px","label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.isDisable)?_c('el-tag',{attrs:{"type":"info"}},[_vm._v("禁用")]):_c('el-tag',{attrs:{"type":"success"}},[_vm._v("启用")])]}}])}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","width":"160px","prop":"creationTime","label":"创建时间"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm._f("DateFormat")(scope.row.creationTime))+" ")]}}])})],1),_c('el-pagination',{attrs:{"background":"","current-page":_vm.queryForm.pageIndex,"page-size":_vm.queryForm.pageSize,"layout":_vm.layout,"total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}}),_c('edit',{ref:"edit",on:{"fetch-data":_vm.fetchData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }